<template>
  <div>
    <form name="punchout_form" method="post" action="https://openapi-sandbox.dl.alipaydev.com/gateway.do?charset=UTF-8&method=alipay.trade.wap.pay&sign=cnCGjJxVjv%2FCmBXOsdz6bFt87Iqb7FcDgN1GjIqEq8mjCwxF3WkvLF6tWIepCOgqUAHvlkkoR5RX4TOeGBmR%2Fb7bqWa4TkiVjpb4%2FifK93i29Y6s6SacnvWM0b6k9zmO7qNxny%2FxfT%2FT8eonWN2n8A82VjQ2dl4bsB9rMGN1itI3hj%2B3yTFuZAfSmMq5fccVzKG54x3quHudpALCnXd855yjFBVuSeG7M7pIQFmRI%2FJTU7K6cez7j2mX7Yhyeusk0R8UUvQ03jKKvthIj4DHLxOXaDdvPVQPR0XbBeRX3ixTxn%2BSzCHWXusUGjdRpn4LBWJYvrUYMfbOtgT7pYNXAA%3D%3D&version=1.0&app_id=9021000138603400&sign_type=RSA2&timestamp=2024-06-11+20%3A49%3A14&alipay_sdk=alipay-sdk-java-4.38.61.ALL&format=json">
      <input type="hidden" name="biz_content" value="{&quot;out_trade_no&quot;:&quot;1800510604813803521&quot;,&quot;product_code&quot;:&quot;QUICK_WAP_WAY&quot;,&quot;seller_id&quot;:&quot;2088102147948060&quot;,&quot;subject&quot;:&quot;玄智科技&quot;,&quot;total_amount&quot;:&quot;1&quot;}">
      <input type="submit" value="立即支付" >
    </form>
  </div>
</template>

<script>

export default {

  data: function () {
    return {
      formData: this.$route.params.formData,
    }
  },


  mounted() {
  },

  methods: {

  }


}
</script>
<style lang="css" scoped>
@import './pay.css';
</style>